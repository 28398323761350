<template>
  <div class="filter__section">
    <div class="filter__section__name">
      <label v-if="label" class="filter__section__label" :for="name || `form-input-${$.uid}`">
        {{ label }}
      </label>
      <button
        class="filter__section__clear"
        v-show="clearable && selectedItems.length"
        @click.prevent.stop="clear"
      >
        Clear
      </button>
    </div>
    <div class="filter__wrap" :class="{ 'mt-3': displayItems.length || !active }">
      <div class="filter__active__values">
        <span
          v-for="(option, index) in displayItems"
          :key="index"
          class="filter__active__value include"
          :class="option.type"
        >
          {{ option?.label || option }}
          <Icon name="input-close" class="w-4 h-4 ms-1" @click.stop="onDeselect(option)" />
        </span>
        <button class="filter__toggle" v-if="!active" @click="toggleDropdown">
          <Icon name="svg-add" class="w-4 h-4 me-2" />
          Include/Exclude
        </button>
      </div>
      <div class="filter__field">
        <div class="filter__dropdown" v-show="active">
          <Multiselect
            ref="multiselectRef"
            :id="name || `form-input-${$.uid}`"
            :name="name || `form-input-${$.uid}`"
            :mode="mode"
            :options="
              apiUrl
                ? async function (query) {
                    return await search(query)
                  }
                : options
            "
            :required="required"
            :disabled="disabled"
            :loading="loading"
            :placeholder="placeholder"
            v-model="selectedItems"
            :searchable="searchable"
            :can-clear="false"
            :allowAbsent="true"
            :hideSelected="true"
            :closeOnSelect="false"
            :clearOnBlur="false"
            autocomplete="hack-to-turn-of-autocomplete-in-chrome"
            :clearOnSearch="!!apiUrl"
            :filter-results="!apiUrl"
            :resolveOnLoad="!!apiUrl"
            :min-chars="1"
            :delay="delay"
            value-prop="label"
            track-by="value"
            :value-key="valueKey"
            :label-key="labelKey"
            :classes="{
              wrapper:
                'relative mx-auto w-full flex items-center justify-between box-border cursor-pointer outline-none p-1',
              container:
                'multiselect-custom relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-500 rounded-lg bg-white text-base leading-snug outline-none',
              options: 'flex flex-col items-start px-2 py-0.5 gap-1.5',
              option: 'hidden flex justify-between items-center p-2 px-3 w-full',
              optionSelected: 'bg-primary-100 border border-primary-400 rounded-lg',
              optionSelectedPointed:
                'bg-primary-100 border border-primary-400 rounded-lg hover:bg-primary-200',
              optionPointed: 'text-gray-800 bg-danger-150 rounded-lg hover:bg-secondary-100',
              tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 px-2 min-w-0 rtl:pl-0 rtl:pr-2 overflow-hidden gap-2',
              tag: ' hidden flex flex-row items-center p-0 px-2 bg-primary-50 rounded font-medium text-sm text-primary-700 pr-1',
              dropdown:
                'max-h-60 absolute -left-px -right-px transform bottom-[-5px] translate-y-full border border-gray-500 rounded-lg mt-2 overflow-y-scroll z-50 bg-white flex flex-col',
              dropdownHidden: 'hidden',
              containerDisabled: 'border-secondary-200',
              tagsSearch:
                'absolute inset-0 border-0 outline-none focus:ring-0 focus:appearance-none p-0 text-base font-sans box-border w-full',
              spinner: 'hidden'
            }"
            @select="select"
            @change="change"
            @close="close"
          >
            <template #spinner>
              <div
                v-if="loadingOptions"
                class="max-h-60 bottom-[-5px] absolute -left-px -right-px transform translate-y-full border border-gray-500 rounded-lg mt-2 overflow-y-scroll z-50 bg-white flex flex-col"
                tabindex="-1"
              >
                <InputLoader class="m-auto my-5" />
              </div>
            </template>

            <template #beforelist="{ options }">
              <div
                v-for="(option, i) in options"
                :key="i"
                class="flex px-3 py-2 m-2 hover:bg-secondary-100 rounded-lg"
                @click.stop="onInclude(option)"
              >
                <span class="font-normal text-sm text-secondary-900">
                  {{ option.label }}
                </span>
                <div class="ms-auto select-actions">
                  <span class="select-action" @click.stop="onInclude(option)">Include</span>
                  <span class="select-action" @click.stop="onExclude(option)">Exclude</span>
                </div>
              </div>
            </template>

            <template #caret>
              <Icon
                v-show="!!multiselectRef?.search"
                name="input-close"
                class="h-4 w-4 me-3.5 text-secondary-400"
                @click="clearSearch"
              />
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'

import Icon from '@/components/Icon/Icon.vue'
import Multiselect from '@vueform/multiselect'
import InputLoader from '@/components/Inputs/InputLoader.vue'

interface IProps {
  mode?: 'single' | 'multiple' | 'tags'
  label?: string
  name?: string
  values?: string[]
  loading?: boolean
  required?: boolean
  disabled?: boolean
  placeholder?: string
  searchable?: boolean
  options: any[] | string[]
  clearable?: boolean
  apiUrl?: string
  paramName?: string
  queryParams?: Record<string, string>
  valueKey?: string
  labelKey?: string
  delay?: number
}

const props = withDefaults(defineProps<IProps>(), {
  values: () => [],
  loading: false,
  clearable: true,
  searchable: true,
  mode: 'tags',
  placeholder: 'Type here',
  paramName: 'query',
  delay: -1
})

const emit = defineEmits(['clear', 'change', 'select', 'search-change'])

const active = ref<boolean>(false)
const multiselectRef = ref(null)
const displayItems = ref<{ value: string; label: string; type: string }[]>(props.values)
const selectedItems = ref(props.values.map((v) => v?.label || v))
const loadingOptions = ref(false)

watch(
  () => props.values,
  (newValues) => {
    displayItems.value = newValues
    selectedItems.value = newValues.map((v) => v?.label || v)
  }
)

const clear = () => {
  multiselectRef.value.clear()
  displayItems.value = []
  emit('clear', displayItems.value)
}
const clearSearch = () => {
  multiselectRef.value.clearSearch()
}

const onDeselect = (option) => {
  multiselectRef.value.deselect(option)
  displayItems.value = displayItems.value.filter((item) => item.label !== option.label)
  emit('clear', displayItems.value)
}

const onInclude = (option) => {
  option.type = 'include'
  multiselectRef.value.select(option)
}

const onExclude = (option) => {
  option.type = 'exclude'
  multiselectRef.value.select(option)
}

const select = (val, option) => {
  if (!option?.type) {
    return
  }

  const index = displayItems.value.findIndex((item) => item.label == val)

  if (index === -1) {
    displayItems.value.push(option)
  } else {
    displayItems.value.splice(index, 1, option)
  }

  emit('select', displayItems.value)
}

const change = (data) => {
  emit('change', displayItems.value)
}

const search = async (query) => {
  if (query) {
    loadingOptions.value = true

    try {
      const apiUrl = new URL(props.apiUrl)
      apiUrl.searchParams.set(props.paramName, query)

      if (props.queryParams) {
        Object.entries(props.queryParams).forEach(([key, value]) => {
          apiUrl.searchParams.set(key, value)
        })
      }

      const res = await axios.get(apiUrl.toString())

      if (res.status === 200) {
        return res.data.map((item) => {
          return {
            value: item[props.valueKey || 'value'],
            label: item[props.labelKey || 'label']
          }
        })
      } else {
        return []
      }
    } catch (err) {
      console.error(err)
      captureException(err)
    } finally {
      loadingOptions.value = false
    }
  }
}

const toggleDropdown = () => {
  active.value = !active.value
}
const close = () => {
  active.value = false
}
</script>

<style scoped>
.filter__section {
  @apply border-b last:border-b-0 pb-3 last:pb-0;

  .filter__section__name {
    @apply flex justify-between text-sm font-medium;
  }

  .filter__section__label {
    @apply text-secondary-900;
  }

  .filter__section__clear {
    @apply text-secondary-400 underline hover:no-underline ms-auto;
  }
}

.filter__wrap {
  @apply flex flex-col gap-2;

  .filter__active__values {
    @apply flex flex-wrap gap-2;

    .filter__active__value {
      @apply px-2 rounded flex text-sm leading-6 font-medium;
    }

    .filter__active__value.include {
      @apply bg-success-50 text-success;
    }

    .filter__active__value.exclude {
      @apply bg-danger-50 text-danger;
    }
  }

  .filter__toggle {
    @apply flex text-primary text-sm font-medium;
  }
}

.select-actions {
  @apply flex gap-2 max-h-5 self-center;

  .select-action {
    @apply text-xs leading-5 font-medium text-secondary-400 no-underline hover:underline first:pe-2 first:border-r first:border-secondary-400;
  }
}

.multiselect-custom ::-webkit-scrollbar {
  width: 4px;
}

.multiselect-custom ::-webkit-scrollbar-track {
  background: #edeef1;
  border-radius: 50px;
}

.multiselect-custom ::-webkit-scrollbar-thumb {
  background: #b3bac6;
  border-radius: 24px;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
