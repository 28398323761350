<template>
  <SearchResultsLoader v-if="pageLoading" />
  <div v-else class="flex lg:flex-row flex-col">
    <div class="lg:min-w-90 max-w-90 border-r border-gray-100">
      <Filters :open="isFilterOpen" :table="table" @close="closeFilter" @apply="applyFilters" />
    </div>
    <!-- Beginning of Right side -->

    <div class="p-4 md:p-6 flex-1">
      <SearchResultsLoader v-if="loadingNewResults" without-left-section />
      <div v-else>
        <Breadcrumbs />
        <div
          class="flex flex-col lg:justify-between mt-4 md:mt-6"
          :class="{
            'lg:items-center  lg:flex-row-reverse': searchStore.searchResults?.results?.length
          }"
        >
          <!-- Edit and Save Search -->
          <div class="flex gap-4">
            <Button
              variant="outline-secondary"
              class="flex items-center"
              icon="svg-edit"
              @click="editSearch"
            >
              <Icon name="svg-edit" class="size-5 me-2" />
              Edit Search
            </Button>
            <SaveSearchButton
              v-if="searchStore.searchResults?.results?.length"
              @saveSearchError="handleSaveSearchError"
            />
          </div>

          <!-- Title -->
          <div v-if="searchStore.searchResults?.results?.length" class="mt-6 md:mt-4 lg:mt-0">
            <h1 class="text-3xl leading-8 text-secondary-900 font-bold capitalize">
              {{ searchStore.searchPayload?.title.join(', ') }}
            </h1>
          </div>
        </div>
        <!-- Experience duration-->
        <div v-if="searchStore.searchResults?.results?.length" class="experience-container">
          <div
            v-if="
              searchStore.searchPayload?.total_role_exp &&
              searchStore.searchPayload?.total_role_exp > 0
            "
            class="exp-item"
          >
            <Icon name="input-time" class="exp-item__icon" />
            <span class="exp-item__title">
              Role: {{ Math.floor(searchStore.searchPayload?.total_role_exp / 12) }}+ years
            </span>
          </div>
          <div
            v-if="
              searchStore.searchPayload?.career_exp && searchStore.searchPayload?.career_exp > 0
            "
            class="exp-item"
          >
            <Icon name="input-time" class="exp-item__icon" />
            <span class="exp-item__title">
              Career: {{ Math.floor(searchStore.searchPayload.career_exp / 12) }}+ years
            </span>
          </div>
          <div v-if="searchStore.searchType === 'url'" class="max-sm:hidden ml-auto flex">
            <span class="font-medium text-base leading-5 text-primary-700 self-center">
              Display Archetype Summary
            </span>
            <ToggleButton
              class="self-center"
              @toggle="showArchetypeSummary = !showArchetypeSummary"
              :toggle-state="showArchetypeSummary"
            />
          </div>
        </div>

        <ArchetypeSummary
          v-if="
            searchStore.searchResults?.results?.length &&
            showArchetypeSummary &&
            searchStore.searchType === 'url'
          "
          class="mt-4"
          :data="searchStore.searchResults?.archetypeData"
        />

        <!-- Number of results and table actions tablet and desktop -->
        <div
          v-if="searchStore.searchResults?.results?.length"
          class="hidden mt-9 pl-2 md:flex justify-between"
        >
          <div class="ms-0 me-auto">
            <span
              class="text-lg md:text-xl lg:text-2xl leading-8 font-medium md:font-normal text-secondary-900"
            >
              {{ Object.entries(table.getPaginationRowModel().rowsById).length.toLocaleString() }}
              search results
            </span>
          </div>

          <div v-if="searchStore.searchResults?.results?.length" class="flex gap-4">
            <div class="flex">
              <span class="font-medium text-base leading-5 text-primary-700 self-center">
                Shortlisted
              </span>
              <ToggleButton
                class="self-center"
                label=""
                @toggle="filterShortlisted"
                :toggle-state="route.query.shortlisted === 'true'"
              />
            </div>
            <SearchResultSortMenu :table="table" @sort="currentPage = 1" />
            <Button variant="outline-secondary" class="hidden lg:flex" @click="exportTable">
              <Icon name="svg-download" class="size-5 me-2" />
              Export
            </Button>
            <Button variant="outline-primary" class="flex gap-2 lg:hidden px-3" @click="openFilter">
              <Icon name="svg-filter" class="size-5" />
              Filters
              <span class="filters-count">5</span>
            </Button>
            <Button
              variant="primary"
              :loading="riskScoreLoading"
              animation="dots"
              class="flex justify-center items-center px-3"
              @click="sortByRiskScore"
            >
              <template #loading>
                <Icon name="svg-stars" class="size-5 me-2" />Predicting
              </template>
              <Icon name="svg-stars" class="size-5 me-2" />
              Predicted Interest
            </Button>
          </div>
        </div>
        <div v-else class="flex flex-row justify-center mt-15">
          <div class="flex flex-col">
            <span class="text-4xl font-semibold leading-8 text-grey-900"
              >Sorry, No Candidate Results Found</span
            >
            <span class="text-2xl font-semibold leading-10 text-grey-900 pt-12"
              >We recommend either:</span
            >
            <div class="flex">
              <ol
                class="list-disc list-inside pl-3 text-2xl font-semibold leading-10 text-grey-900"
              >
                <li>
                  <Link
                    variant="default"
                    :to="
                      searchStore.searchType === 'title'
                        ? '/?tab=job-title&prefill=true'
                        : '/?tab=linkedin&prefill=true'
                    "
                    link-type="link"
                    class="!text-2xl font-semibold leading-10 text-grey-900"
                  >
                    Editing your search
                  </Link>
                </li>
                <li>Modifying the filters</li>
                <li>
                  Starting a
                  <Link
                    variant="default"
                    :to="searchStore.searchType === 'title' ? '/?tab=job-title' : '/?tab=linkedin'"
                    link-type="link"
                    class="!text-2xl font-semibold leading-10 text-grey-900"
                  >
                    new search
                  </Link>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <!-- Number of results and table actions mobile -->
        <div
          v-if="searchStore.searchResults?.results?.length"
          class="md:hidden mt-9 flex flex-col gap-6 justify-between"
        >
          <div class="flex gap-4 justify-between">
            <Button variant="outline-secondary" class="hidden lg:flex" @click="exportTable">
              <Icon name="svg-download" class="size-5 me-2" />
              Export
            </Button>
            <Button variant="outline-primary" class="flex gap-2 lg:hidden px-3" @click="openFilter">
              <Icon name="svg-filter" class="size-5" />
              Filters
              <span class="filters-count">5</span>
            </Button>
            <Button
              variant="primary"
              :loading="riskScoreLoading"
              animation="dots"
              class="flex justify-center items-center px-3"
              @click="sortByRiskScore"
            >
              <template #loading>
                <Icon name="svg-stars" class="size-5 me-2" />Predicting
              </template>
              <Icon name="svg-stars" class="size-5 me-2" />
              Predicted Interest
            </Button>
          </div>

          <div class="flex justify-between">
            <div class="flex">
              <span class="font-medium text-base leading-5 text-primary-700 self-center">
                Shortlisted
              </span>
              <ToggleButton
                class="self-center"
                label=""
                @toggle="filterShortlisted"
                :toggle-state="route.query.shortlisted === 'true'"
              />
            </div>
            <SearchResultSortMenu :table="table" />
          </div>
        </div>

        <!-- Search Results Table -->
        <div
          v-if="searchStore.searchResults?.results?.length"
          class="mt-4 md:border md:border-gray-100 md:rounded-lg md:pt-4 md:px-6"
        >
          <div class="flex flex-col gap-4 md:gap-0 md:divide-y mb-6 md:mb-0">
            <template v-for="row in table.getRowModel().rows" :key="row.id">
              <SearchResultCandidateCard
                :candidate-data="row"
                :projects="projects"
                @shortlist-error="handleShortlistError"
                @project-created="handleProjectCreated"
                @project-creation-error="handleProjectCreationError"
                @addTo-project-error="handleAddToProjectError"
                @addTo-project-success="handleAddToProjectSuccess"
                @candidate-data-error="handleCandidateDataError"
              />
            </template>
          </div>
          <Pagination
            v-if="searchStore.searchResults?.results?.length"
            v-model="currentPage"
            :records="Object.entries(table.getPaginationRowModel().rowsById).length"
            :per-page="table.getState().pagination.pageSize"
            @paginate="handlePagination"
            :options="paginationOptions"
          />
        </div>
      </div>
    </div>
    <Message
      v-if="shortlistError"
      class="fixed bottom-0 w-full h-15"
      :timeout="3000"
      @timeout="shortlistError = false"
      type="error"
      :message="shortlistErrorMessage"
    />
    <Message
      v-if="error"
      class="fixed bottom-0 w-full h-15"
      :timeout="5000"
      @timeout="error = false"
      type="error"
      :message="errorMessage"
    />
    <Message
      v-if="success"
      class="fixed bottom-0 w-full h-15"
      :timeout="3000"
      @timeout="success = false"
      type="success"
      :message="successMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount, onUnmounted, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import axios from 'axios'
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useVueTable
} from '@tanstack/vue-table'
import Pagination from 'v-pagination-3'
import { mkConfig, generateCsv, download } from 'export-to-csv'

import Filters from '@/views/Results/Components/Filters.vue'
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import SearchResultCandidateCard from '@/components/Card/Candidate/SearchResultCandidateCard.vue'
import NewPagination from '@/components/NewPagination.vue'

import ArchetypeSummary from '@/components/ArchetypeSummary/ArchetypeSummary.vue'
import { useSearchStore } from '@/stores/search'

import { convertMonthsToYearsMonths } from '@/utils/date'

import Breadcrumbs from '@/components/Breadcrumb/Breadcrumbs.vue'
import SearchResultsLoader from '@/components/Results/SearchResultsLoader.vue'
import SearchResultSortMenu from '@/components/Results/SearchResultSortMenu.vue'
import Message from '@/components/Message/Message.vue'
import SaveSearchButton from '@/views/Results/Components/SaveSearchButton.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import Link from '@/components/Link/Link.vue'
import type { SearchResults } from '@/stores/types'

const searchStore = useSearchStore()
const route = useRoute()
const router = useRouter()
const columnHelper = createColumnHelper<SearchResults['results']>()

const currentPage = ref(1)
const paginationOptions = ref({
  template: NewPagination,
  chunk: 10
})
const isFilterOpen = ref(false)
const pageLoading = ref(false)
const loadingNewResults = ref(false)
const error = ref(false)
const errorMessage = ref('')
const showArchetypeSummary = ref(searchStore.searchType === 'url' ? true : false)
const riskScoreLoading = ref(false)
const tableData = computed(() => searchStore.searchResults?.results as SearchResults['results'])
const shortlistError = ref(false)
const shortlistErrorMessage = ref('')
const projects = ref([])
const success = ref(false)
const successMessage = ref('')

const includeExcludeTitleFilter = (
  row,
  columnId: string,
  filterValue: { label: string; type: string }[]
) => {
  if (filterValue.length === 0) {
    return true
  }

  if (filterValue.some((filter) => filter.type === 'include')) {
    return filterValue.some(
      (filter) =>
        filter.type === 'include' && row.original.title.toLowerCase() === filter.label.toLowerCase()
    )
  } else {
    return filterValue.some(
      (filter) =>
        filter.type === 'exclude' && row.original.title.toLowerCase() !== filter.label.toLowerCase()
    )
  }
}

const columns = [
  columnHelper.accessor('ranking_order', {
    enableSorting: true
  }),
  columnHelper.accessor('name', {}),
  columnHelper.accessor('title', {
    enableColumnFilter: true,
    filterFn: includeExcludeTitleFilter
  }),
  columnHelper.accessor('industry', {}),
  columnHelper.accessor('company_name', {
    sortingFn: 'alphanumeric',
    enableSorting: true
  }),
  columnHelper.accessor('country', {}),
  columnHelper.accessor('state', {}),
  columnHelper.accessor('city', {}),
  columnHelper.accessor('final_risk_score', {
    enableSorting: true
  }),
  columnHelper.accessor('total_experience_in_position', {
    enableSorting: true,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('total_career_experience', {
    enableSorting: true
  }),
  columnHelper.accessor('shortlisted', {
    enableColumnFilter: true
  })
]

const table = useVueTable({
  defaultColumn: {
    enableSorting: false
  },
  data: tableData,

  initialState: {
    sorting: [
      {
        id: 'ranking_order',
        desc: false
      }
    ],

    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 25 //custom default page size
    }
  },
  filterFns: {
    includeExclude: includeExcludeTitleFilter // Register the custom filter function
  },
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(), //client-side filtering
  getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
  getSortedRowModel: getSortedRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  autoResetPageIndex: true
})

const riskScoreColumn = table.getColumn('final_risk_score')
const positionColumn = table.getColumn('title')
const shortlistedColumn = table.getColumn('shortlisted')

const updateDynamicValue = () => {
  const screenWidth = window.innerWidth
  if (screenWidth < 640) {
    paginationOptions.value.chunk = 3
  } else if (screenWidth < 1440) {
    paginationOptions.value.chunk = 5
  } else {
    paginationOptions.value.chunk = 10
  }
}

onBeforeMount(async () => {
  if (
    !searchStore.searchResults ||
    searchStore.searchId !== parseInt(route.params.searchId as string)
  ) {
    pageLoading.value = true
    try {
      await searchStore.fetchSearchResults(parseInt(route.params.searchId as string))
      searchStore.setSearchId(parseInt(route.params.searchId as string))

      const titleInclude = searchStore.searchPayload?.title_include?.map((title) => ({
        value: title,
        label: title,
        type: 'include'
      }))

      const titleExclude = searchStore.searchPayload?.title_exclude?.map((title) => ({
        value: title,
        label: title,
        type: 'exclude'
      }))

      const titles = [...(titleInclude || []), ...(titleExclude || [])]

      positionColumn?.setFilterValue(titles)
    } catch {
      error.value = true
      errorMessage.value = 'Oops! Something went wrong, please contact support.'
    }
    pageLoading.value = false
  }

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
  showArchetypeSummary.value = searchStore.searchType === 'url'
})

onMounted(() => {
  updateDynamicValue() // Initial check
  window.addEventListener('resize', updateDynamicValue)
  if (route.query.shortlisted === 'true') {
    shortlistedColumn?.setFilterValue(true)
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', updateDynamicValue)
})

const handleSaveSearchError = (err: Error) => {
  error.value = true
  errorMessage.value = err.message
}

const handleShortlistError = (error: Error) => {
  shortlistError.value = true

  shortlistErrorMessage.value = error.message
}

const handlePagination = (page: number) => {
  table.setPageIndex(page - 1)
  currentPage.value = page
}

const openFilter = () => {
  isFilterOpen.value = true
}

const closeFilter = () => {
  isFilterOpen.value = false
}

const sortByRiskScore = (event) => {
  if (riskScoreColumn.getSortIndex() === 0) {
    table.resetSorting()
  } else {
    riskScoreLoading.value = true

    setTimeout(() => {
      riskScoreColumn.toggleSorting(true)
      riskScoreLoading.value = false
    }, 2000)
  }
}

const applyFilters = async (filters) => {
  isFilterOpen.value = false
  if (searchStore.searchType === 'title' && !filters.onlyTitleFilterChanged) {
    await jobTitleSearch(filters)
  } else if (searchStore.searchType === 'url' && !filters.onlyTitleFilterChanged) {
    await urlSearch(filters)
  }
  positionColumn?.setFilterValue(filters.title)

  if (filters.onlyTitleFilterChanged) {
    axios.put(`${import.meta.env.VITE_LOOKALIKES_API}/api/search/update-title-filters`, {
      searchId: searchStore.searchId,
      title_include: filters.title_include,
      title_exclude: filters.title_exclude
    })
  }
  table.resetSorting()

  currentPage.value = 1
}

const jobTitleSearch = async (filters) => {
  const payload = {
    locations: filters.locations,
    location_radius: filters.location_radius,
    title: searchStore.searchPayload?.title,
    title_include: filters.title_include,
    title_exclude: filters.title_exclude,
    canonical_title: searchStore.searchPayload?.canonical_title,
    total_role_exp: filters.total_role_exp ? filters.total_role_exp * 12 : 0,
    career_exp: filters.career_exp ? filters.career_exp * 12 : 0,
    industries_include: filters.industries_include,
    industries_exclude: filters.industries_exclude,
    companies_include: filters.companies_include,
    companies_exclude: filters.companies_exclude,
    company_size_include: filters.company_size_include,
    company_size_exclude: filters.company_size_exclude,
    past_experience: searchStore.searchPayload?.past_experience
  }

  try {
    loadingNewResults.value = true
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/search/title`, {
      ...payload,
      industries: filters.industries
    })

    searchStore.setSearchState(res.data, payload)
    router.push({ name: 'searchResults', params: { searchId: res.data.search_id } })
  } catch (err) {
  } finally {
    loadingNewResults.value = false
  }
}

const urlSearch = async (filters) => {
  const searchPayload = {
    archetype_name: searchStore.searchPayload?.archetype_name,
    linkedin_profile_url: searchStore.searchPayload?.linkedin_profile_url,
    locations: filters.locations,
    location_radius: filters.location_radius,
    title: searchStore.searchPayload?.title,
    title_include: filters.title_include,
    title_exclude: filters.title_exclude,
    canonical_title: searchStore.searchPayload?.canonical_title,
    total_role_exp: filters.total_role_exp ? filters.total_role_exp * 12 : 0,
    career_exp: filters.career_exp ? filters.career_exp * 12 : 0,
    industries_include: filters.industries_include,
    industries_exclude: filters.industries_exclude,
    companies_include: filters.companies_include,
    companies_exclude: filters.companies_exclude,
    company_size_include: filters.company_size_include,
    company_size_exclude: filters.company_size_exclude,
    past_experience: searchStore.searchPayload?.past_experience,
    archetype_exp_history_from_linkedin:
      searchStore.searchPayload?.archetype_exp_history_from_linkedin,
    archetype_cache_id: searchStore.searchPayload?.archetype_cache_id
  }

  try {
    loadingNewResults.value = true
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/search/linkedin-url`, {
      ...searchPayload,
      industries: filters.industries
    })

    searchStore.setSearchState(res.data, searchPayload, [], [])
    router.push({ name: 'searchResults', params: { searchId: res.data.search_id } })
  } catch (err) {
  } finally {
    loadingNewResults.value = false
  }
}
const exportTable = () => {
  const data = table.getPrePaginationRowModel().flatRows.map((row) => {
    return {
      Name: row.original.name,
      Position: row.original.title,
      Employer: row.original.company_name,
      Industry: row.original.industry,
      Location: [row.original.country, row.original.state, row.original.city]
        .filter((x) => x)
        .join(', '),
      'Total Role Exp':
        row.original.total_experience_in_position !== null
          ? convertMonthsToYearsMonths(row.original.total_experience_in_position)
          : 'N\\A',
      'Career Exp':
        row.original.total_career_experience !== null
          ? convertMonthsToYearsMonths(row.original.total_career_experience)
          : 'N\\A',
      'Linkedin Profile URL': `https://www.linkedin.com/in/${row.original.profile_id}`
    }
  })

  const fileName = `${searchStore.searchPayload?.title}_${convertMonthsToYearsMonths(
    searchStore.searchPayload?.total_role_exp
  )}_Role_Exp `

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `lookalikes_search_results_${fileName}_${new Date().toLocaleDateString()}`
  })
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}

const filterShortlisted = (toggle: boolean) => {
  // this is so that the new shortlisted candidates are displayed
  searchStore.searchResults.results = [...searchStore.searchResults.results]

  shortlistedColumn?.setFilterValue(toggle ? true : undefined)

  currentPage.value = 1
}

const handleProjectCreated = async () => {
  success.value = true
  successMessage.value = 'Project created successfully'

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const handleProjectCreationError = (errMessage: string) => {
  error.value = true
  errorMessage.value = errMessage
}

const handleAddToProjectSuccess = async (showMessage: boolean) => {
  if (showMessage) {
    success.value = true
    successMessage.value = 'Candidate added to project successfully'
  }

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const handleAddToProjectError = (errMessage: string) => {
  error.value = true
  errorMessage.value = errMessage
}

const editSearch = () => {
  let tab = ''
  if (searchStore.searchType === 'url') {
    tab = 'linkedin'
  } else {
    tab = 'job-title'
  }
  router.push({
    name: 'homePage',
    query: {
      tab: tab,
      prefill: 'true'
    }
  })
}

const handleCandidateDataError = () => {
  error.value = true
  errorMessage.value = 'Oops! Something went wrong, please try again.'
}
</script>

<style>
.experience-container {
  @apply flex flex-row flex-wrap gap-1 font-normal mt-2;

  .exp-item {
    @apply flex items-center px-2 py-1 text-secondary-700;

    .exp-item__icon {
      @apply size-4 me-1;
    }

    .exp-item__title {
      @apply text-sm;
    }
  }
}

.filters-count {
  @apply size-6 text-sm leading-6 self-center text-current bg-primary-50 rounded;
}
</style>
