<template>
  <SectionBlock title="Current Position">
    <div class="flex flex-col md:flex-row">
      <label
        for="jobTitle"
        class="text-sm text-secondary-900 font-medium w-full leading-6 md:max-w-37.5 mb-2 md:me-6"
      >
        Job title:
      </label>

      <SelectInput
        class="w-full"
        :api-url="jobTitleAutocompleteApi"
        name="jobTitle"
        placeholder="Type and select"
        :delay="500"
        value-key="canonical_title"
        label-key="title"
        clearable
        :clear-on-blur="true"
        param-name="title"
        save-values-as-objects
        :selected-value="selectedValue ?? editSearchSelectedValue"
        required
        @change="changeJobTitle"
        :hide-option-if-search-empty="true"
      />
    </div>
  </SectionBlock>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import SectionBlock from '@/components/Tabs/SectionBlock.vue'
import SelectInput from '@/components/Inputs/Select/SelectInput.vue'

import { useSearchStore } from '@/stores/search'

interface IProps {
  selectedValue: string[]
}

const props = withDefaults(defineProps<IProps>(), {
  selectedValue: null
})

const emit = defineEmits(['jobTitleChange'])
const route = useRoute()
const searchStore = useSearchStore()
const editSearchSelectedValue = ref<{ label: string; value: string } | null[]>([])
const jobTitleAutocompleteApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/position-title-auto-complete`

// const locationQueryParams = computed(() => {
//   return {
//     countries: JSON.stringify(searchStore.searchLocation.countries),
//     states: JSON.stringify(searchStore.searchLocation.states),
//     cities: JSON.stringify(searchStore.searchLocation.cities)
//   }
// })

onMounted(() => {
  if (route.query.prefill && searchStore.searchPayload?.title) {
    editSearchSelectedValue.value = searchStore.searchPayload.title.map((item, index) => ({
      label: item,
      value: searchStore.searchPayload.canonical_title[index]
    }))
    emit('jobTitleChange', editSearchSelectedValue.value)
  }
})

const changeJobTitle = (option) => {
  emit('jobTitleChange', option)
}
</script>
